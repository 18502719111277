<template>
  <div>
    <h2>{{ $t("priceBox.header") }}</h2>
    <div v-if="!productAvailable()">
      <p>
        {{ $t("priceBox.unavailable") }}
      </p>
    </div>
    <div v-if="productAvailable() && !showCartSuccess">
      <div v-if="showPurchaseOptions" class="section">
        <p class="section-title">{{ $t("priceBox.quantity") }}</p>
        <div>
          <input type="text" v-model="productAmount" />
          {{ $t('priceBox.itemsAvailable', { items: itemsAvailable }) }}
          <p v-if="msgProductAmount" class="error">{{ msgProductAmount }}</p>
        </div>
      </div>

      <div v-if="showPurchaseOptions" class="section">
        <p class="section-title">{{ $t('priceBox.price') }}</p>
        <p class="hint">
          {{ $t("priceBox.pickYourPrice1") }}
          <router-link :to="{ name: 'priceSlider' }">{{ $t("priceBox.pickYourPrice2") }}</router-link>
        </p>
        <Slider class="price-slider" v-model="productPrice" :min="minimumPrice" :max="maximumPrice" :format="format"
          :tooltipPosition="top" />
      </div>

      <div v-if="showPurchaseOptions" class="section">
        <p class="section-title">{{ $t("priceBox.support") }}</p>
        <div class="project-support" v-if="projectSupport == 0">
          {{ $t("priceBox.moveToRight") }}
        </div>
        <div class="project-support" v-if="projectSupport > 0">
          {{ $t('priceBox.donationThankYou', { amount: projectSupport }) }}
        </div>
        <div class="project-support" v-if="projectSupport < 0">
          {{ $t("priceBox.noDonation") }}
        </div>
      </div>

      <div v-if="!showSuccess" class="section">
        <p class="section-title">{{ $t("priceBox.totalPrice") }}</p>
        <p class="total-price">
          {{ $t('priceBox.totalPriceCalculated', { price: total }) }}
        </p>
        <p class="hint" v-if="(total < freeShippingThreshold)">
          {{ $t('priceBox.freeShipping') }}
        </p>
      </div>

      <button class="purchase" v-if="!showCartSuccess" @click.stop="putIntoCart()">{{
        $t('priceBox.putIntoCart')
      }}</button>
      <OutsideEURestrictions />
    </div>
    <div v-if="showCartSuccess">
      <p> {{ $t('priceBox.shoppingCartSuccess') }}</p>
      <p><router-link :to="{ name: 'cart' }">{{ $t("priceBox.seeCart") }}</router-link></p>
    </div>
  </div>
</template>

<script>
import Slider from '@vueform/slider'
import { axiosInstance } from '@/library'
import OutsideEURestrictions from './OutsideEURestrictions.vue';

export default {
  name: "PriceBox",

  props: ['product'],

  components: {
    Slider,
    OutsideEURestrictions
  },

  data: () => ({
    showPurchaseOptions: true,
    showPaypal: true,
    showSuccess: false,
    showCartSuccess: false,
    productAmount: 1,
    productPrice: 0,
    itemsAvailable: 0,
    freeShippingThreshold: 20,  // TODO this should come from granja.eu
    msgProductAmount: '',
    format: function (value) {
      return `${Math.round(value)} €`
    }
  }),

  watch: {
    productAmount(value) {
      this.productAmount = value
      this.validateAmount(value)
    }
  },

  computed: {
    total() {
      return this.productPrice * this.productAmount
    },

    projectSupport() {
      return (this.productPrice - this.startPrice) * this.productAmount
    }
  },

  beforeMount() {
    this.resetValues()
  },

  methods: {
    validateAmount(value) {
      if (value < 0)
        this.msgProductAmount = 'Product amount not valid'
      else
        this.msgProductAmount = ''

      if (value > this.itemsAvailable)
        this.msgProductAmount = 'That amount is not available'
      else
        this.msgProductAmount = ''
    },

    productAvailable() {
      return this.itemsAvailable > 0
    },

    resetValues() {
      axiosInstance.get(`${window.VUE_APP_GRANJA}/api/sales/inventory/${this.product}`)
        .then(response => {
          if (response.status === 200) {
            this.itemsAvailable = response.data.productQuantity
            this.minimumPrice = response.data.minimumPriceInCents / 100
            this.maximumPrice = response.data.maximumPriceInCents / 100
            this.startPrice = response.data.recommendedPriceInCents / 100
            this.productPrice = this.startPrice
          }
        })
        .catch(console.error)

      this.showPurchaseOptions = true
      this.showPaypal = false
      this.showSuccess = false
      this.showCartSuccess = false
    },

    putIntoCart() {
      const item = {
        productId: this.product,
        quantity: this.productAmount,
        customerPriceInCents: this.productPrice * 100
      }

      const shoppingCartLocation = this.$cookies.get('shoppingCartLocation')
      console.log('shoppingCartLocation', shoppingCartLocation)
      if (shoppingCartLocation === null) {
        axiosInstance.post(`${window.VUE_APP_GRANJA}/api/sales/carts`, JSON.stringify(item), {
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            this.$cookies.set('shoppingCartLocation', response.headers['location'])
            this.showCartSuccess = true
          })
      } else {
        const cartId = shoppingCartLocation.substring(shoppingCartLocation.lastIndexOf('/') + 1)

        axiosInstance.post(`${window.VUE_APP_GRANJA}/api/sales/carts/${cartId}`, JSON.stringify(item), {
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            console.log(response)
            this.showCartSuccess = true
          })
      }
    },


  }
};
</script>

<style src="@vueform/slider/themes/default.css">

</style>

<style scoped>
DIV.section {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.section-title {
  font-weight: bold;
  background-color: #F0F0F0;
  text-align: center;
  padding: 0.3em;
}

.hint {
  font-size: smaller;
}

.price-slider {
  margin-top: 4em;
  margin-bottom: 2em;
}

.project-support {
  background-color: #E9FEEB;
  padding: 0.5em;
}

TABLE.price THEAD {
  border-bottom: solid black;
}

TABLE.price TD {
  text-align: center;
}

.error {
  color: red
}

input[type="text"] {
  width: 2em;
  padding: 0.2em;
  margin-right: 1em;
  font-size: larger;
}

.total-price {
  font-size: larger;
  font-weight: bold;
}
</style>
