<template>
  <div>
    <div v-if="languageCode('es')">
      <div v-if="!showContent" data-cy="cookiesNotAllowedMessage" class="cookiesNotAllowed flex">
        <div>
          <p>
            No aceptaste el uso de cookies. No podemos mostrarle algunos contenidos en esta ubicación.
          </p>
          <p>
            ¿Quieres permitir las cookies ahora?
          </p>
        </div>
        <div>
          <button data-cy="accept" @click="accept" class="accept">Aceptar</button>
        </div>

      </div>
      <slot v-if="showContent" data-cy="cookieControlledContent"></slot>
    </div>
    <div v-else-if="languageCode('de')">
      <div v-if="!showContent" data-cy="cookiesNotAllowedMessage" class="cookiesNotAllowed flex">
        <div>
          <p>
            Sie haben die Verwendung von Cookies nicht akzeptiert. Wir können Ihnen einige Inhalte an diesem Ort nicht
            zeigen.
          </p>
          <p>
            Möchten Sie jetzt Cookies zulassen?
          </p>
        </div>
        <div>
          <button data-cy="accept" @click="accept" class="accept">Erlauben</button>
        </div>

      </div>
      <slot v-if="showContent" data-cy="cookieControlledContent"></slot>
    </div>
    <div v-else>
      <div v-if="!showContent" data-cy="cookiesNotAllowedMessage" class="cookiesNotAllowed flex">
        <div>
          <p>
            You did not accept the use of cookies. We cannot show you some content at this location.
          </p>
          <p>
            Do you want to allow cookies now?
          </p>
        </div>
        <div>
          <button data-cy="accept" @click="accept" class="accept">Accept</button>
        </div>

      </div>
      <slot v-if="showContent" data-cy="cookieControlledContent"></slot>
    </div>
  </div>
</template>

<script>
import { getLanguage } from '@/library'

export default {
  name: 'CookieControlledContent',
  components: {},

  data() {
    return {
      showContent: false
    }
  },

  beforeMount() {
    this.showContent = this.$cookieConsent.accepted()
  },

  methods: {
    languageCode(code) {
      return code === getLanguage()
    },

    accept() {
      this.showContent = true
      this.$cookies.set('cookie-consent', true)
      this.$cookieConsent.accept()
    },

  }

}
</script>
